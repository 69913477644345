<template>
	<div class="products">

		<Head title="Messagerie"></Head>

		<v-container>
			<v-row>
				<v-col
					cols="12"
					lg="7"
				>
					<v-card
						class="glassmorphism pa-4 pt-0 mx-auto"
						max-width="1280"
						color="transparent"
					>
						<v-card-title class="pl-0">
							<h2 class="font-weight-regular pt-1">Tableau de bord</h2>
						</v-card-title>
					</v-card>
				</v-col>
				<v-col
					cols="12"
					lg="5"
				>
					<v-card
						class="glassmorphism pa-4 pt-0 mx-auto pr-0"
						max-width="1280"
						color="transparent"
					>
						<v-card-title class="pl-0">
							<h2 class="font-weight-regular pt-1">Journaux</h2>
						</v-card-title>

						<v-timeline
							dense
							clipped
							reverse
						>
							<v-slide-x-transition
								group
								v-if="this.timelineItems.length"
							>
								<v-timeline-item
									v-for="item in timelineItems"
									:key="item.id"
									class="mb-4"
									color="transparent"
									:icon-color="item.color"
									:icon="`mdi-${item.icon}`"
								>
									<v-row
										justify="space-between"
										class="pt-2"
									>
										<v-col
											cols="6"
											v-html="item.label"
										></v-col>
										<v-col
											class="text-right"
											cols="auto"
										>
											{{ $moment(item.creaDate.toDate()).format('DD/MM/YYYY HH:mm') }}
										</v-col>
										<v-col
											class="text-right"
											cols="1"
										>
											<v-tooltip
												top
												color="success"
											>
												<template v-slot:activator="{ on, attrs }">

													<v-btn
														class="neon"
														color="success"
														small
														icon

														v-bind="attrs"
														v-on="on"

														@click="markAsProcessed(item.id)"
													>
														<v-icon>mdi-check</v-icon>
													</v-btn>
												</template>
												<span>Marquer comme traité</span>
											</v-tooltip>
										</v-col>
									</v-row>
									<v-btn
										class="mt-3 neon evidence"
										color="white"
										outlined
										v-if="false"
									>
										Créer un contact à partir de cette adresse
									</v-btn>
								</v-timeline-item>
							</v-slide-x-transition>
							<v-slide-x-transition v-else>
								<v-timeline-item
									class="mb-4"
									color="success lighten-1"
									icon="mdi-umbrella-beach"
								>
									<v-row
										justify="space-between"
										class="pt-2"
									>
										<v-col cols="7">
											Toutes les entrées ont été traitées, vous pouvez vous reposer.
										</v-col>
										<v-col
											class="text-right"
											cols="auto"
										>
											{{ $moment().format('DD/MM/YYYY HH:mm') }}
										</v-col>
									</v-row>
								</v-timeline-item>
							</v-slide-x-transition>
						</v-timeline>
					</v-card>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
	import Head from '@/components/graphics/head';

	export default {
		head: {
			title: {
				inner: "Produits"
			}
		},
		components: {
			Head
		},
		data() {
			return {
				timelineItems: [],
				user: {}
			};
		},
		async created() {
			this.user = await this.$katana.getUser(this.$firebase.auth().currentUser.uid);

			this.$db.collection("companies").doc(this.user.company).collection("messaging").where("active", "==", true).orderBy("creaDate", "desc").onSnapshot((res) => {
				let items = [];

				res.forEach((doc) => {
					let color = this.$listUtils.iconActions[doc.data().action][1] ? this.$listUtils.iconActions[doc.data().action][1] : "white";
					let icon = this.$listUtils.iconActions[doc.data().action][0];

					items.push({
						label: doc.data().label,
						creaDate: doc.data().creaDate,
						from: doc.data().from,

						icon,
						color,

						id: doc.id
					})
				});

				this.timelineItems = items;
			});
		},

		methods: {
			markAsProcessed(id) {
				this.$db.collection("companies").doc(this.user.company).collection("messaging").doc(id).update({
					active: false,
					update: {
						timestamp: new Date(),
						author: this.$firebase.auth().currentUser.uid
					}
				}).then(() => {
					this.$store.dispatch('setSnackbar', {
						text: "L'entrée a été supprimée.",
						color: "success"
					});
				});
			}
		}

	}
</script>